import React, { Component, useEffect, useState } from "react";

import { Grid, Typography, Card } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import DialogModal from "../../reusableComponents/DialogModal";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
// ----------------Images---------------------

import writtentestimonialImg1 from "../../images/testimonials/Suma.JPG";
import writtentestimonialImg2 from "../../images/testimonials/MeghRaj.jpeg";
import writtentestimonialImg3 from "../../images/testimonials/Vishwanath.jpeg";
import writtentestimonialImg4 from "../../images/testimonials/Sindhu.jpeg";
import writtentestimonialImg5 from "../../images/testimonials/Monish.JPG";

let videoTestmonial_1 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/isaq_1.mp4";
let videoTestmonial_2 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/jeevanib_1.mp4";
let videoTestmonial_3 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/jimmy.mp4";
let videoTestmonial_4 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/lakshay_1.mp4";
let videoTestmonial_5 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/mohan.mp4";
let videoTestmonial_6 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/nitin.mp4";
let videoTestmonial_7 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/pranav_1.mp4";
let videoTestmonial_8 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/shashiKumar.mp4";
let videoTestmonial_9 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/sunil.mp4";
let videoTestmonial_10 =
  "https://aleentapublicimages.s3.ap-south-1.amazonaws.com/testimonial_videos/tarak.mp4";

const writtentestimonialsArray = [
  {
    imgSrc: writtentestimonialImg1,
    content:
      "I am incredibly grateful for the self-transformation session. It helped me break through mental barriers and discover my true potential.Your guidance was invaluable, and I feel inspired and motivated a lot.. Thank you very much for including me as part of this program mam.",
    name: "Suma H",
    company: "Aroha technologies",
  },
  {
    imgSrc: writtentestimonialImg2,
    content:
      "I recently participated in Aleenta Wellbeing’s Self Transformation Program and I can't recommend it enough. Mangala’s expertise and guidance provided me with valuable tools to navigate life's challenges with ease. Through her guidance, I learned practical techniques to manage stress, set meaningful goals and cultivate a deeper sense of inner peace through meditation. I feel more empowered and equipped to lead a balanced and fulfilling life.Thanks to Aleenta Wellbeing for this transformative experience!",
    name: "Megh Raj Rakesh",
    company: "Consultant at EY(Ernst & Young) LLP",
  },
  {
    imgSrc: writtentestimonialImg3,
    content:
      "Every day is an opportunity to introspect and see what can be done to improve and become a better person. Self transformation series of interactive sessions has really helped me and perhaps 1000's individuals - to implement various learnings in our day to day activities. Thank you very much",
    name: "M R Vishwanath",
    company: "Aroha technologies",
  },
  {
    imgSrc: writtentestimonialImg4,
    content:
      "I really enjoyed Mangala Narayan's self-transformation sessions. She taught me valuable things I can use in real life. When I listen to her, I feel happy and positive. She talked about mindfulness, empathy, and ego games, which help in our relationships. I learned how to be happier and accept life better through her examples with Prasadham laddu and Stones. She also spoke about being less shy, managing time well, and understanding our body, mind, and inner spirit. I'm grateful to Ibridge for organizing this helpful course, and I hope they continue offering more sessions like this",
    name: "Sindhu",
    company: "",
  },
  {
    imgSrc: writtentestimonialImg5,
    content:
      "“ I feel I found some parts of me that I had lost. I leave with more clarity, Vision & Peace. I have so much to work on but the tools I have learned will be with me on my journey of self transformation sessions” Special thanks to Mangala Narayan Mam for showing me   Exact path of leading the life peacefully. ",
    name: "Monish Sharma D",
    company: "iBridge360 Edtech Pvt Ltd",
  },
];

const videoTestmonialArray = [
  {
    name: "videoTestmonial_1",
    videoSrc: videoTestmonial_1,
  },
  {
    name: "videoTestmonial_2",
    videoSrc: videoTestmonial_2,
  },
  {
    name: "videoTestmonial_3",
    videoSrc: videoTestmonial_3,
  },
  {
    name: "videoTestmonial_4",
    videoSrc: videoTestmonial_4,
  },
  {
    name: "videoTestmonial_5",
    videoSrc: videoTestmonial_5,
  },
  {
    name: "videoTestmonial_6",
    videoSrc: videoTestmonial_6,
  },
  {
    name: "videoTestmonial_7",
    videoSrc: videoTestmonial_7,
  },
  {
    name: "videoTestmonial_8",
    videoSrc: videoTestmonial_8,
  },
  {
    name: "videoTestmonial_9",
    videoSrc: videoTestmonial_9,
  },
  {
    name: "videoTestmonial_10",
    videoSrc: videoTestmonial_10,
  },
];

const Testimonials = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const [openPopup, setOpenPopup] = useState(false);
  const [videoSrc, setvideoSrc] = useState();

  return (
    <>
      <Grid container className="testimonialsContainer">
        <Grid container style={{ backgroundColor: "#fbf2de" }}>
          <Grid item xs={12} md={12} className="testimonialsContentGrid">
            <Typography className="testimonialsTitle1" align="center">
              Here’s what our clients and participants have to say about our
              programs:
            </Typography>
          </Grid>
          <Grid container>
            {writtentestimonialsArray.map((item) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  align="center"
                  className="testimonialsGridItem"
                  style={{ paddingTop: "2%" }}
                >
                  <img className="testimonialsContentImg" src={item.imgSrc} />
                  <Typography className="testimonialsContent1">
                    {item.content}
                  </Typography>
                  <Typography
                    className="testimonialsContent1"
                    align="Right"
                    style={{ fontWeight: "600" }}
                  >
                    -{item.name}
                    <br />
                    {item.company}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/* <Carousel items={writtentestimonialsArray} /> */}

      {/* ----------------------------------------------------------------------------------------- */}
      <Grid item xs={12} md={12} style={{ marginTop: "4%" }}>
        {/* <Carousel items={videoTestmonialArray} /> */}
        <Grid
          id="carouselExampleIndicators"
          class="carousel slide"
          data-pause="hover"
          data-ride="carousel"
          data-interval="3000"
          style={{ paddingTop: "0px", marginTop: "-15px", paddingBottom: "2%" }}
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">
            {videoTestmonialArray.map((item) => {
              return (
                <div
                  class={
                    videoTestmonialArray.indexOf(item) === 0
                      ? "carousel-item active"
                      : "carousel-item"
                  }
                >
                  <Grid container>
                    <Grid item xs={12} md={12} align="center">
                      <div className="single-item">
                        <video
                          // style={{
                          //   width: "550px",
                          //   height: "100%",
                          //   align: "center",
                          // }}
                          // onClick={() => {
                          //   setOpenPopup(true);
                          //   setvideoSrc(item.videoSrc);
                          // }}
                          id={item.name}
                          className="videoTestimonialVideoComp"
                          src={item.videoSrc}
                          // autoPlay
                          // controls
                          // muted
                          loop
                        />
                        <div className="playIcon">
                          <PlayCircleOutlineIcon
                            style={{
                              color: "white",
                              fontSize: "60px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenPopup(true);
                              setvideoSrc(item.videoSrc);
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <KeyboardArrowLeftIcon
              className="arrow-icons"
              style={{ color: "black" }}
            />
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <KeyboardArrowRightIcon
              className="arrow-icons"
              style={{ color: "black" }}
            />
          </a>
        </Grid>
      </Grid>
      <DialogModal
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        // title={"Testmonial"}
        children={<video src={videoSrc} autoPlay controls loop />}
      />
    </>
  );
};

export default Testimonials;
