// import React, { useState } from "react";
// import { withStyles } from "@material-ui/core/styles";
// import {
//   makeStyles,
//   Button,
//   Typography,
//   Dialog,
//   DialogTitle as MuiDialogTitle,
//   DialogContent as MuiDialogContent,
//   DialogActions as MuiDialogActions,
//   IconButton,
//   Grid,
// } from "@material-ui/core";
// import { Close as CloseIcon } from "@material-ui/icons";

// const useStyles = makeStyles((theme) => ({
//   dialogWrapper: {
//     padding: "0%",
//     margin: "0%",
//     // padding: theme.spacing(2),
//     position: "absolute",
//     top: theme.spacing(5),
//     width: "660px",
//     height: "370px",
//     "@media only screen and (max-width: 960px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "80%",
//       width: "90%",
//     },
//     "@media only screen and (max-width: 800px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "75%",
//       width: "90%",
//     },
//     "@media only screen and (max-width: 700px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "65%",
//       width: "90%",
//     },
//     "@media only screen and (max-width: 600px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "55%",
//       width: "90%",
//     },
//     "@media only screen and (max-width: 500px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "45%",
//       width: "90%",
//     },
//     "@media only screen and (max-width: 400px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "35%",
//       width: "90%",
//     },
//     "@media only screen and (max-width: 300px)": {
//       //   display: "Grid",
//       //   placeItems: "center",
//       //   width: "300px",
//       height: "32%",
//       width: "90%",
//     },
//   },
// }));

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     // padding: theme.spacing(2),
//     // paddingRight: '0px',
//     width: "100px",
//     // width:"1250px"
//     // width:"95%"
//     padding: "0%",
//     "@media only screen and (max-width: 1040px)": {
//       width: "500px",
//     },
//     "@media only screen and (max-width: 600px)": {
//       width: "260px",
//     },
//   },
//   closeButton: {
//     position: "absolute",
//     right: theme.spacing(2),
//     // top: theme.spacing(3),
//     // align:"right",
//     color: theme.palette.grey[500],
//     padding: "0%",
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle
//       disableTypography
//       style={{ padding: "0%" }}
//       className={classes.root}
//       {...other}
//     >
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: "0%",
//   },
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     // padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

// export default function DialogModal({
// title,
// children,
// openPopup,
// setOpenPopup,
// }) {
//   const classes = useStyles();
//   return (
//     <Grid container align="center">
//       <Grid item xs={10} md={10}>
//         <Dialog
//           onClose={() => setOpenPopup(false)}
//           //   aria-labelledby="customized-dialog-title"
//           open={openPopup}
//           maxWidth="lg"
//           style={{ padding: "0%" }}
//           classes={{ paper: classes.dialogWrapper }}
//         >
//           <DialogTitle
//             id="customized-dialog-title"
//             onClose={() => setOpenPopup(false)}
//           >
//             {title}
//           </DialogTitle>
//           <DialogContent>{children}</DialogContent>
//         </Dialog>
//       </Grid>
//     </Grid>
//   );
// }

import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
export default function DialogModal({
  title,
  children,
  openPopup,
  setOpenPopup,
}) {
  // const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openPopup}
      >
        {title ? (
          <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        ) : null}

        {children}
      </Dialog>
    </div>
  );
}
